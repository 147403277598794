import React, { FunctionComponent } from "react";
import { ImageInterface } from "../../types/SanityTypes";
import Section from "../Section";

import "./styles.scss";

interface FeaturePromoBlockInterface {
  blocks: [
    {
      _rawLogo: ImageInterface;
      description: string;
    }
  ];
  bgColor: {
    value: string;
    title: string;
  };
}

const FeaturePromoBlock: FunctionComponent<FeaturePromoBlockInterface> = props => {
  const { bgColor, blocks } = props;

  return (
    <Section sectionClassName="feature-promo-block" theme={bgColor.title}>
      {blocks && blocks.length ? (
        <div className="feature-promo-block__wrapper">
          {blocks.map((blockItem, index) => {
            return (
              <div className="feature-promo-block__item" key={index}>
                <img
                  src={blockItem._rawLogo.asset.url}
                  width={40}
                  height={40}
                  className="feature-promo-block__item-image"
                  alt={blockItem._rawLogo.alt}
                />
                {blockItem.description ? (
                  <p className="feature-promo-block__item-description">{blockItem.description}</p>
                ) : null}
              </div>
            );
          })}
        </div>
      ) : null}
    </Section>
  );
};

export default FeaturePromoBlock;
